// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jmp-js": () => import("./../../../src/pages/jmp.js" /* webpackChunkName: "component---src-pages-jmp-js" */),
  "component---src-pages-new-index-js": () => import("./../../../src/pages/new_index.js" /* webpackChunkName: "component---src-pages-new-index-js" */),
  "component---src-pages-parks-js": () => import("./../../../src/pages/parks.js" /* webpackChunkName: "component---src-pages-parks-js" */),
  "component---src-pages-parks-social-ties-online-js": () => import("./../../../src/pages/parks_social_ties_online.js" /* webpackChunkName: "component---src-pages-parks-social-ties-online-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-skillpage-js": () => import("./../../../src/pages/skillpage.js" /* webpackChunkName: "component---src-pages-skillpage-js" */),
  "component---src-pages-teaching-js": () => import("./../../../src/pages/teaching.js" /* webpackChunkName: "component---src-pages-teaching-js" */)
}

